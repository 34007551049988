<template>
  <div>
    <CRow v-if="bells != null">
      <CCol sm="12">
        <button
          type="button"
          class="btn-sm btn-info float-right"
          @click="mtd_back_to_table"
        >
          <i class="fas fa-arrow-left"></i>&nbsp;Regresar
        </button>
      </CCol>
      <br /><br />
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong> DETALLE DE SALIDA</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="3" md="3" sm="12" xs="12">
                <CInput
                  label="Cantidad de salida(Kg)"
                  placeholder="Cantidad"
                  v-model="search.amount"
                  v-on:keypress="isNumber($event)"
                  :disabled="view != 1 ? true : false"
                />
              </CCol>
              <CCol lg="7" md="7" sm="12" xs="12">
                <label for="">Elegir detalle</label>
                <v-select
                  :options="data_product_entry"
                  multiple
                  v-model="search.product_entry"
                  :disabled="view != 1 ? true : false"
                  placeholder="Seleccione detalle(s)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="12" xs="12">
                <label for="">&nbsp;</label>
                <br />
                <CButton
                  color="info"
                  v-c-tooltip.hover="{
                    content: 'Buscar acopios',
                  }"
                  :disabled="view == 1 ? cp_search : true"
                  @click="mtd_search_acopio"
                >
                  <i class="fas fa-search"></i> Buscar
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <div v-if="view == 1">
          <CCard v-if="boo_detail == true">
            <CCardHeader>
              <strong> Lista de acopios</strong>
              <CBadge class="float-right pt-2" size="sm" color="success"><h6><strong>Total seleccionado: {{ cp_total_seleccionado }}</strong></h6></CBadge>
            </CCardHeader>

            <CCardBody>
              <CRow v-for="(item, index) in data" :key="index">
                <CButton
                  block
                  color="link"
                  class="text-left shadow-none card-header"
                  @click="
                    accordion =
                      accordion === item.product_entry_id
                        ? false
                        : item.product_entry_id
                  "
                >
                  <h5 class="m-0">{{ item.product_entry_name }}</h5>
                </CButton>
                <CCol lg="12" md="12" xs="12" sm="12">
                  <CCollapse :show="accordion === item.product_entry_id">
                    <CCardBody>
                      <CRow v-if="item.acopios.length > 0">
                        <CCol
                          class="position-relative table-responsive"
                          lg="12"
                          md="12"
                          xs="12"
                          sm="12"
                        >
                          <!-- <div class="position-relative table-responsive"> -->
                          <table
                            class="
                              table
                              table-striped
                              table-fixed
                              table-hover
                              table-bordered
                            "
                          >
                            <thead>
                              <tr>
                                <th>&nbsp;</th>
                                <th>Ticket</th>
                                <th>Certificado</th>
                                <th>Proveedor</th>
                                <th>Peso Neto (kg)</th>
                                <th>Cantidad a usar</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(acopio, ind_acopio) in item.acopios"
                                :key="ind_acopio"
                                style="heigth: 10px;"
                              >
                                <td style="text-align: center;vertical-align:middle;">
                                  <input
                                    type="checkbox"
                                    @change="mtd_select_item(acopio)"
                                    v-model="totalAdd"
                                    :id="'move_'+ind_acopio"
                                    :value="'move_'+ind_acopio"
                                  />
                                </td>
                                <td style="vertical-align:middle;">{{ acopio.ticket }}</td>
                                <td style="vertical-align:middle;">{{ acopio.certification }}</td>
                                <td style="vertical-align:middle;">{{ acopio.supplier }}</td>
                                <td style="vertical-align:middle;">{{ acopio.neto_after }}</td>
                                <td style="vertical-align:middle;">
                                  <CInput
                                    label=""
                                    size="sm"
                                    placeholder="Cantidad a enviar"
                                    v-model="acopio.amount"
                                    @blur="mtd_out_focus(ind_acopio)"
                                    :disabled="acopio.disabled"
                                    v-on:keypress="isNumber($event)"
                                  >
                                  </CInput>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!-- </div> -->
                        </CCol>
                        <CCol
                          lg="12"
                          md="12"
                          xs="12"
                          sm="12"
                          class="text-center"
                        >
                          <CButton
                            size="sm"
                            color="info"
                            v-c-tooltip.hover="{
                              content: 'Continuar',
                            }"
                            @click="mtd_continue(2)"
                          >
                            Continuar <i class="fas fa-arrow-right"></i>
                          </CButton>
                        </CCol>
                      </CRow>
                      <CRow v-else>
                        <h4>
                          <strong>
                            NO EXISTEN REGISTROS PARA ESTE DETALLE</strong
                          >
                        </h4>
                      </CRow>
                    </CCardBody>
                  </CCollapse>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </div>
        <div v-if="view == 2">
          <CCard>
            <CCardHeader>
              <strong> Lista de salida</strong>
              <!-- <button type="button" class="btn-sm btn-info float-right" @click="mtd_back_to(1)"><i class="fas fa-arrow-left"></i>&nbsp;Regresar</button>  -->
              <CBadge class="float-right pt-2" size="sm" color="success"><h6><strong>Total sacos: {{ cp_total_sacos }}</strong></h6></CBadge>
              &nbsp;&nbsp;
              <CBadge class="float-right pt-2 mr-2" size="sm" color="success"><h6><strong>Total bruto: {{ cp_total_bruto }}</strong></h6></CBadge>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol
                  class="position-relative table-responsive"
                  lg="12"
                  md="12"
                  xs="12"
                  sm="12"
                >
                  <table
                    class="
                      table table-striped table-fixed table-hover table-bordered
                    "
                  >
                    <thead>
                      <tr>
                        <th>Detalle</th>
                        <th>Proveedor</th>
                        <th>Peso neto</th>
                        <th>Humedad</th>
                        <th>N° sacos</th>
                        <th>Peso bruto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in action_selection"
                        :key="index"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.supplier }}</td>
                        <td>{{ item.amount }}</td>
                        <td>
                          <CInput
                            label=""
                            size="sm"
                            placeholder="Humedad"
                            v-model="item.humedad"
                            v-on:keypress="isNumber($event)"
                          >
                          </CInput>
                        </td>
                        <td>
                          <CInput
                            label=""
                            size="sm"
                            placeholder="N° sacos"
                            v-model="item.sacos"
                            v-on:keypress="isNumber($event)"
                          >
                          </CInput>
                        </td>
                        <td>
                          <CInput
                            label=""
                            size="sm"
                            placeholder="Peso bruto"
                            :disabled="true"
                            :value="mtd_peso_bruto(index)"
                          >
                          </CInput>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CCol>
                <CCol class="text-center" lg="12" md="12" xs="12" sm="12">
                  <CButton
                    size="sm"
                    color="info"
                    v-c-tooltip.hover="{
                      content: 'Continuar',
                    }"
                    @click="mtd_back_to(1)"
                  >
                    <i class="fas fa-arrow-left"></i> Regresar
                  </CButton>
                  &nbsp;
                  <CButton
                    size="sm"
                    color="success"
                    v-c-tooltip.hover="{
                      content: 'Continuar',
                    }"
                    @click="mtd_continue(3)"
                  >
                    Continuar <i class="fas fa-arrow-right"></i>
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </div>
        <div v-if="view == 3">
          <CCard>
            <CCardHeader>
              <strong> <i class="fa fa-book"></i> Datos del lote</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol lg="4" md="4" xs="12" sm="12">
                  <label for="">Elegir destino</label>
                  <v-select
                    :options="data_destinations"
                    v-model="lote.destination"
                    placeholder="Seleccione destino"
                  />
                </CCol>
                <CCol lg="4" md="4" xs="12" sm="12">
                  <CInput
                    label="Guía de remision"
                    placeholder="Guía de remision"
                    v-model="lote.guia_remision"
                  >
                  </CInput>
                </CCol>
                <CCol lg="4" md="4" xs="12" sm="12">
                  <label for="">Elegir transportista</label>
                  <v-select
                    :options="data_transports"
                    v-model="lote.transport"
                    placeholder="Seleccione transportista"
                  />
                </CCol>
                <CCol lg="4" md="4" xs="12" sm="12">
                  <CInput
                    label="Guía de transportista"
                    placeholder="Guía de transportista"
                    v-model="lote.guia_transportista"
                  >
                  </CInput>
                </CCol>
                <CCol lg="4" md="4" xs="12" sm="12">
                  <CTextarea
                    label="Referencia"
                    placeholder="Referencia"
                    v-model="lote.reference"
                  >
                  </CTextarea>
                </CCol>
                <CCol lg="4" md="4" xs="12" sm="12">
                  <CInput
                    label="Responsable"
                    placeholder="Responsable"
                    v-model="lote.responsable"
                  >
                  </CInput>
                </CCol>
                <CCol class="text-center" lg="12" md="12" sm="12" xs="12">
                  <CButton
                    size="sm"
                    color="info"
                    v-c-tooltip.hover="{
                      content: 'Continuar',
                    }"
                    @click="mtd_back_to(2)"
                  >
                    <i class="fas fa-arrow-left"></i> Regresar
                  </CButton>
                  &nbsp;
                  <CButton
                    size="sm"
                    color="success"
                    v-c-tooltip.hover="{
                      content: 'Guardar',
                    }"
                    :disabled="cp_button_save"
                    @click="mtd_save_send"
                  >
                    Continuar <i class="fas fa-save"></i>
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </div>
      </CCol>
      
    </CRow>
    <CRow v-else>
      <CCol col="12">
        <CCard>
          <CCardHeader> <strong> CAMPAÑA NO INICIADA </strong> </CCardHeader>
          <CCardBody>
            COMUNIQUESE CON EL SUPERADMINISTRADOR PARA INICIAR UNA CAMPAÑA Y
            PODER CONTINUAR.
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="text-left">
      
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "v-proccess-sendacopio",
  components: { vSelect },
  data() {
    return {
      prefix: "acopio",
      view: 1,
      bells: null,
      data_product_entry: [],
      data_destinations: [],
      data_transports: [],
      boo_detail: false,
      // sumatotal: 0,
      totalAdd: [],
      search: {
        amount: "",
        product_entry: [],
      },
      accordion: 0,
      data: [],
      action_selection: [],
      lote: {
        amount: "",
        product_entry: [],
        destination: [],
        guia_remision: "",
        transport: [],
        guia_transportista: "",
        reference: "",
        responsable: "",
      },
      total_selection:0,
    };
  },
  computed: {
    cp_search() {
      if (this.search.amount > 0 && this.search.product_entry.length > 0) {
        return false;
      } else {
        return true;
      }
    },

    cp_button_save: function () {
      if (
        this.lote.guia_remision.length > 0 &&
        this.lote.guia_transportista.length > 0 &&
        this.lote.reference.length > 0 &&
        this.lote.responsable.length > 0
      )
        return false;
      return true;
    },
    cp_total_seleccionado: function(){
      let total = 0;
      this.action_selection.forEach(element => {
         total += parseFloat(element.amount)
      });
      this.total_selection = parseFloat(total).toFixed(2);
      return parseFloat(total).toFixed(2);
    },
    cp_total_sacos: function(){
      let tot_sacos = 0;
      this.action_selection.forEach(element => {
        let to = element.sacos != ""? parseFloat(element.sacos) : 0;
        tot_sacos += to;
      });
      return parseFloat(tot_sacos).toFixed(2);
    },
    cp_total_bruto: function(){
      let tot_bruto = 0;
      this.action_selection.forEach(element => {
        let to = element.peso_bruto != ""? parseFloat(element.peso_bruto) : 0;
        tot_bruto += to;
      });
      return parseFloat(tot_bruto).toFixed(2);
    }
  },
  created() {
    this.mtd_getdata();
    this.action_selection=[];
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_back_to_table: function () {
      this.$router.go(-1);
    },
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix + "/send",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.bells = response.bells;
          this.data_product_entry = response.data_product_entry;
          this.data_destinations = response.data_destinations;
          this.data_transports = response.data_transports;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_search_acopio: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/send/search",
        token: this.$store.getters.get__token,
        params: { search: this.search, bell: this.bells },
      })
        .then((response) => {
          this.boo_detail = true;
          this.data = response;
          this.totalAdd = [];
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    // suma_total_cantidad: function (col) {
    //   let finalData = JSON.parse(JSON.stringify(this.totalAdd));
    //   const arrOfNum = finalData.map((str) => {
    //     return Number(str);
    //   });
    //   this.sumatotal = arrOfNum.reduce((a, b) => a + b, 0);
    //   this.sumatotal = parseFloat(this.sumatotal).toFixed(2);
    // },
    mtd_select_item: function (col) {
      if (this.action_selection.length === 0) {
        col.disabled = false;
        this.action_selection.push(col);
        // this.suma_total_cantidad(col);
      } else {
        let verif = this.mtd_verif_exits_selection(col.movement_id);
        if (verif == false) {
          col.disabled = false;
          this.action_selection.push(col);
        } else {
          let temp = [];
          this.action_selection.forEach((element) => {
            if (element.movement_id != col.movement_id) {
              element.disabled = false;
              temp.push(element);
            } else {
              element.disabled = true;
            }
          });
          this.action_selection = temp;
        }
      }
    },
    mtd_verif_exits_selection: function (movement) {
      let boo = false;
      this.action_selection.forEach((element) => {
        if (element.movement_id == movement) {
          boo = true;
        }
      });
      return boo;
    },
    mtd_out_focus: function (pos, amount) {
      let item = this.action_selection[pos];
      if (parseFloat(item.amount).toFixed(2) <= parseFloat(item.neto_after)) {
        this.action_selection[pos].amount = parseFloat(item.amount).toFixed(2);
      } else {
        this.action_selection[pos].amount = "";
        bus.$emit("alert", {
          color: "warning",
          message: "LA CANTIDAD NO PUEDE SER MAYOR AL PESO NETO.",
        });
      }
    },
    mtd_continue: function (view) {
      if (view == 2) {
        let tot = 0;
        // this.action_selection.forEach((element) => {
        //   tot += parseFloat(element.amount).toFixed(2);
        // });
        console.log(parseFloat(this.total_selection),parseFloat(this.search.amount));
        console.log(parseFloat(this.search.amount - this.total_selection));
        if (parseFloat(this.search.amount) == parseFloat(this.total_selection)) {
        // if (parseFloat(this.search.amount - tot) === 0) {
          this.view = 2;
        } else {
          bus.$emit("alert", {
              color: "warning",
              message: "LA SUMA SELECCIONADA DEBE SER IGUAL AL MONTO DIGITADO!!",
            });
        }
      } else if (view == 3) {
        let boo_view_3 = true;
        this.action_selection.forEach((element) => {
          if (parseFloat(element.peso_bruto) == 0) {
            boo_view_3 = false;
          }
        });
        if (boo_view_3 == true) {
          this.view = 3;
        } else {
          alert("no ok");
        }
      }
    },
    mtd_back_to: function (view) {
      this.view = view;
    },
    mtd_peso_bruto: function (pos) {
      let item = this.action_selection[pos];
      if (item.humedad > 0 && item.sacos > 0) {
        let op1 = parseFloat(0.25 * item.sacos);
        let peso = parseFloat(item.amount) + parseFloat(op1);
        this.action_selection[pos].peso_bruto = parseFloat(peso).toFixed(2);
      } else {
        this.action_selection[pos].peso_bruto = 0.0;
      }
      return this.action_selection[pos].peso_bruto;
    },
    mtd_save_send: function () {
      this.lote.amount = this.search.amount;
      this.lote.product_entry = this.search.product_entry;
      this.post({
        url: this.$store.getters.get__url + "/lote/store",
        token: this.$store.getters.get__token,
        params: {
          lote: this.lote,
          detail: this.action_selection,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            this.view = 1;
            this.boo_detail = false;
            this.search = {
              amount: "",
              product_entry: [],
            };
            this.action_selection = [];
            this.lote = {
              amount: "",
              product_entry: [],
              destination: [],
              guia_remision: "",
              transport: [],
              guia_transportista: "",
              reference: "",
              responsable: "",
            };
            bus.$emit("alert", {
              color: "success",
              message: "SALIDA DE ACOPIO GUARDADO CORRECTAMENTE!!",
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.exception,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table th,
.table td {
  padding: 0.3rem;
  // vertical-align: top;
  border-top: 1px solid;
  border-top-color: #d8dbe0;
}
</style>